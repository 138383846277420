<template>
  <section class="content">
    <div class="modal show" tabindex="-1" role="dialog" ref="formComment" data-backdrop="static">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ ticketTitle }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-0">

            <form role="form" @submit.prevent="submitForm">
              <div>
                <h3>KODE #{{ code }}</h3>
                <p>Catatan : {{ catatan }}</p>
              </div>
              <div class="alert alert-success" v-if="ticketStatus == 2">{{ updatedBy }} on {{ updatedOn }}</div>
              <div class="alert"
                v-bind:class="{ 'alert-claim-2 text-dark': returnClaim == '2', 'alert-claim-3': returnClaim == '3' }"
                v-if="returnClaim">
                Note :
                <span v-if="returnClaim == 1">Paket Diclaim</span>
                <span v-else-if="returnClaim == 2">Claim disetujui</span>
                <span v-else-if="returnClaim == 3">Claim ditolak</span>
                {{ return_claim_done }}
              </div>
            </form>
          </div>
          <div class="direct-chat-messages" style="height: auto">
            <!-- Message. Default to the left -->
            <div class="direct-chat-msg" v-for="comment in comments">
              <div class="direct-chat-infos clearfix">
                <span class="direct-chat-name float-left">{{
                    comment.who
                }}</span>
                <span class="direct-chat-timestamp float-right">{{
                    comment.time
                }}</span>
              </div>

              <div v-if="comment">
                <!-- /.direct-chat-img -->
                <div class="direct-chat-primary" :class="{ 'direct-chat-info': comment.message.split(' ')[1] == 'DiBanding', 'direct-chat-danger': comment.message.split(' ')[1] == 'DiTolak', 'direct-chat-success': comment.message.split(' ')[1] == 'DiSetujui' }">
                  <div class="right">
                    <div class="direct-chat-text ml-0" v-if="comment.claim == true">
                      {{ comment.message }}
                    </div>
                  </div>
                  <div class="direct-chat-text ml-0" v-if="comment.claim == false">
                    {{ comment.message }}
                  </div>
                </div>
              </div>
              <ul style="padding-left: 0">
                <li v-for="file in comment.attachments" style="list-style: none;display: inline-block;margin-right: 5px;">
                  <a style="cursor: pointer" @click="openImg(file)"><i class="fa fa-paperclip"></i> {{ file.name }}</a>
                </li>
              </ul>
              <!-- /.direct-chat-text -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <LightBox ref="lightbox" :media="media" :show-caption="true" :show-light-box="false" :showThumbs="false"
      :showFooterCount="false">
    </LightBox>
  </section>
</template>
<script>
import { authFetch, charsRemover } from "@/libs/hxcore";
import $ from "jquery";
import LightBox from "vue-image-lightbox";

import vSelect from "vue-select";
import maskedInput from "vue-masked-input";
import { CurrencyInput } from "vue-currency-input";
import "vue-select/dist/vue-select.css";
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");
export default {
  name: "TicketComment",
  props: { show: Boolean, value: Object, orderId: String },
  data() {
    return {
      ticketTitle: "Lihat Ticket",
      errors: [],
      categories: [],
      priorityOpt: [],
      comments: [],
      method: "POST",
      selectedId: 0,
      defaultId: 1,
      addrOptions: [],
      addrJne: [],
      courierBranchOption: [],
      formTitle: "Buat Tiket",
      files: [],
      loaded: false,
      code: "",
      catatan: "",
      ticketStatus: '',
      form: {
        //to create pickup address
        id: "",
        message: "",
        attachments: "",
        refcode: "",
      },
      imgshow: true,
      media: [
        {
          // For image
          thumb: "",
          src: "",
          caption: "",
        },
      ],
      returnClaim: "",
    };
  },
  components: {
    vSelect,
    maskedInput,
    LightBox,
  },
  created() { },
  methods: {
    openImg: function (file) {
      var attr = {
        thumb: file.link,
        src: file.link,
        caption: file.name,
      };
      this.media = [attr];
      this.$refs.lightbox.showImage(0);
      return false;
    },
    submitForm: function (ev) {
      console.log(this.form, this.files);
      var items = [];
      var refcode = this.form.refcode;
      for (var i in this.files) {
        items.push(this.files[i].code);
      }
      this.form.attachments = items.join(",");
      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      authFetch("/ticket/comment", {
        method: "POST",
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          if (js.success) {
            this.form = { refcode };
            this.files = [];
            this.loadTicket(this.orderId);
            this.$root.$emit("reloadDashboardTicket");
            this.$root.$emit("reloadMenuTicket");
          }
        });
    },
    showUpload: function (ev) {
      //$(this.$refs.formUpload).modal("show");
      $(this.$refs.userfile).click();
    },
    uploadFiles: function (event) {
      const e = this.$refs;
      var input = event.target;
      this.files = input.files;
      var formData = new FormData();
      for (var i in this.files) {
        formData.append("file[]", input.files[i]);
      }
      formData.append("refcode", this.orderId);
      //formData.append("subdir", "/ticket/" + new Date().getTime() / 1000);
      authFetch("/ticket/upload", {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": null,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          this.files = json;
        });
    },
    removeFile: function (code) {
      let newFiles = this.files.filter(function (arr, i) {
        return arr.code != code;
      });
      this.files = newFiles;
    },
    loadTicket: function (id) {
      authFetch("/ticket/arsip_order/" + id).then((res) => {
        res.json().then((json) => {
          if (json.success) {
            this.ticketTitle = json.data.title;
            this.comments = json.comments;
            this.form.id = json.data.id;
            this.code = json.data.no_resi;
            this.catatan = json.data.catatan;
            this.ticketStatus = json.data.status;
            this.updatedBy = json.data.updatedBy;
            this.updatedOn = json.data.updatedOn;
            this.returnClaim = json.data.return_claim;
            this.claim = json.comments.claim;
            this.return_claim_done = json.data.return_claim_done;
            //this.categories = json.data;
          }
        });
      });
    },
  },
  mounted() {
    // console.log("warehouse1", this.value);
    const e = this.$refs;
    // if (this.show === true) $(e.formSelectPickup).modal("show");
    // console.log(this.show);         
    $(e.formComment).on("show.bs.modal", (e) => {
      //if (!this.loaded) {
      this.loaded = true;
      this.loadTicket(this.orderId);
      //this.$root.$emit("reloadDashboardTicket");
      //}
    });
  },
  watch: {
    show: function (old, val) {
      $(this.$refs.formComment).modal("show");
    },
    orderId: function (old, val) {
      this.form.refcode = this.orderId;
    },
    value: function (old, val) { },
    form: {
      handler(val) {
        this.form.message = charsRemover(val.message);
      },
      deep: true
    },
  },
};
</script>
<style scoped>
.alert-claim-2 {
  color: #ffffff;
  background: #66ff33;
  border-color: #60F030;
}

.alert-claim-3 {
  color: #ffffff;
  background: #ff582e;
  border-color: #F0542B;
}
</style>