const sideMenushipper = [
    { label: 'Dashboard', url: '/dashboard-cs', icon: 'fi fi-rr-dashboard' },
    {
        label: 'Buat Pesanan',
        url: '#',
        icon: 'fi fi-rr-add document',
        menus: [
            { label: 'Entri Manual', url: '/order/create/single', icon: '' },
            { label: 'Import File', url: '/order/import', icon: '' },
        ]
    },
    { label: 'Daftar Pesanan', url: '/order', icon: 'fi fi-rr-ballot' },
    {
        label: 'Biaya Kiriman',
        url: '#',
        icon: 'fi fi-rr-map-marker-home',
        menus: [
            { label: 'Cek Ongkir', url: '/cek-ongkir', icon: '' },
            { label: 'Kalkulator', url: '/calculator', icon: '' },
        ]
    },
    { label: 'Cek Resi', url: '/cek', icon: 'fi fi-rr-search location' },
    { label: 'Riwayat Kiriman', url: '/riwayat/all', icon: 'fi fi-rr-time-past' },
    { label: 'Riwayat Buyer', url: '/buyer', icon: 'fi fi-rr-users-alt' },
    {
        label: 'Tiket & Klaim',
        url: '#',
        icon: 'fi fi-rr-ticket',
        menus: [{
                label: 'Buat Tiket Baru',
                url: () => {
                    //this.$root.$emit('createTicket', 3);
                },
                icon: ''
            },
            { label: 'Daftar Tiket', url: '/settings/tickets/all', icon: '' },
            { label: 'Daftar Klaim', url: '/settings/tickets/claim', icon: '' }
        ]
    },
    {
        label: 'Pengaturan',
        url: '#',
        icon: 'fi fi-rr-settings',
        menus: [
            { label: 'Profil', url: '/profile', icon: '' },
            // { label: 'Cek Ongkir', url: '/cek-ongkir', icon: '' },
            { label: 'Kode Tujuan', url: '/settings/destination', icon: '' }
        ]
    },

];
export default sideMenushipper;