const sideMenushipper = [
    { label: 'Dashboard', url: '/dashboard', icon: 'fi fi-rr-dashboard' },
    {
        label: 'Buat Kiriman',
        url: '#',
        icon: 'fi fi-rr-add document',
        menus: [
            { label: 'Entri Manual', url: '/order/create/single', icon: '' },
            { label: 'Entri Batch', url: '/order/import', icon: '' },
        ]
    },
    {
        label: 'Pengiriman',
        url: '#',
        icon: 'fi fi-rr-ballot',
        menus: [
            { label: 'Riwayat Kiriman', url: '/riwayat/all', icon: '' },
            { label: 'Draft Kiriman', url: '/riwayat/draft-kiriman', icon: '' },
            { label: 'Kiriman Batch', url: '/order', icon: '' },
            { label: 'Katalog Produk', url: '/settings/katalog', icon: '' },
            { label: 'Riwayat Buyer', url: '/buyer', icon: '' },
        ]
    },
    {
        label: 'Biaya Kiriman',
        url: '#',
        icon: 'fi fi-rr-map-marker-home',
        menus: [
            { label: 'Cek Ongkir', url: '/cek-ongkir', icon: '' },
            { label: 'Kalkulator', url: '/calculator', icon: '' },
        ]
    },
    { label: 'Cek Resi', url: '/cek', icon: 'fi fi-rr-search-location' },
    // { label: 'Kode Tujuan', url: '/', icon: 'fi fi-rr-road' },
    {
        label: 'BukuBos',
        url: '#',
        icon: 'fi fi-rr-money-bill-wave',
        menus: [
            { label: 'Saldo', url: '/saldo', icon: '' },
            { label: 'Ongkir', url: '/payment-shipper/all', icon: '' },
            { label: 'Ongkir Retur', url: '/payment-shipper/retur', icon: '' }
        ]
    },
    {
        label: 'Tiket & Klaim',
        url: '#',
        icon: 'fi fi-rr-ticket',
        menus: [{
                label: 'Buat Tiket Baru',
                url: () => {
                    // this.$root.$emit('createTicket', 3);
                },
                icon: ''
            },
            { label: 'Daftar Tiket', url: '/settings/tickets/all', icon: '' },
            { label: 'Daftar Klaim', url: '/settings/tickets/claim', icon: '' },
        ]
    },
    {
        label: 'Pengaturan',
        url: '#',
        icon: 'fi fi-rr-settings',
        menus: [
            { label: 'Pengaturan Kurir', url: '/kustom-kurir', icon: '' },
            { label: 'Rekening', url: '/settings/rekening', icon: '' },
            { label: 'Admin Shipper', url: '/settings/user', icon: '' },
            { label: 'Buat PIN', url: '/settings/pin', icon: '' },
            { label: 'Kode Tujuan', url: '/settings/destination', icon: '' },
            { label: 'Info Ketentuan', url: '/info/ketentuan', icon: '' },
            // { label: 'Alamat', url: '/settings/warehouse', icon: '' },
            // { label: 'Customer Service', url: '/settings/user', icon: '' },
            // { label: 'Kode Alamat JNE', url: '/settings/destination/jne', icon: '' },
            // { label: 'Kode Alamat IDX', url: '/settings/destination/idx', icon: '' },
        ]
    },
    {
        label: 'Profil',
        url: '#',
        icon: 'fi fi-rr-user',
        menus: [
            { label: 'Data Diri', url: '/profile', icon: '' },
            { label: 'Verifikasi KTP', url: '/verification', icon: '' },
            { label: 'Membership', url: '/shipper/membership', icon: '' },
            { label: 'Afiliasi', url: '/afiliasi-shipper-detail', icon: '' },
        ]
    },
    {
        label: 'Arsip',
        url: '#',
        icon: 'fi fi-rr-folder-times',
        menus: [
            { label: 'Riwayat Kiriman', url: '/arsip/riwayat', icon: '' },
            { label: 'Daftar Tiket', url: '/arsip/tiket', icon: '' },
            { label: 'Daftar Klaim', url: '/arsip/klaim', icon: '' },
        ]
    },
    { label: 'Pusat Bantuan', url: '/', icon: 'fi fi-rr-headset' },
];
export default sideMenushipper;