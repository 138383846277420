import Vue from 'vue'
import App from './App.vue'
import router from './router'
import AppLayout from '@/layouts/AppLayout'
import VueLazyLoad from 'vue-lazyload'
import Vuelidate from 'vuelidate'
import GoogleAuth from '@/libs/google.js'
import VueGtag from "vue-gtag";
import 'overlayscrollbars/css/OverlayScrollbars.css'
import 'overlayscrollbars/js/jquery.overlayScrollbars.js'
require('@/libs/adminlte')
require('@/libs/bootstrap.min')
require('@/libs/gtag')
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.css';
import '@/assets/style.css'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);
getAnalytics();

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

Vue.use(Vuelidate)
Vue.use(VueLazyLoad)
const gauthOption = {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    scope: 'profile email',
    prompt: 'select_account'
}
Vue.use(GoogleAuth, gauthOption);
Vue.use(VueGtag, {
    config: { id: "G-CY6T6JW83X" }
});

Vue.config.productionTip = false
Vue.config.enableChat = true
var appConfig = window.appConfig;
window.$apiUrl = appConfig ? appConfig.endpointURL : process.env.VUE_APP_ENDPOINT;
window.$crossDomain = true;
if (process.env.NODE_ENV === 'production') {
    window.$crossDomain = false;
}

Vue.config.appHost = process.env.VUE_APP_HOST;
Vue.prototype.loadMenu = false;
new Vue({
    router,
    render: h => h(App)
}).$mount('#app');