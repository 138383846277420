<template>
	<section class="content">
		<!-- <div class="modal fade" tabindex="0" role="dialog" ref="formNewNumber" data-backdrop="static"> -->
		<div ref="formNewNumber" class="modal fade" id = 'otpModal' data-backdrop="false" tabindex="-1" role="dialog" aria-hidden="true">

			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="backPin">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
			<div class="modal-body text-center" v-if="issetPin">
						<div class="alert alert-danger alert-sm" v-if="!pinCheck">{{pinMessage}}</div>
						<div class="mb-4" style="font-style: normal;font-weight: 600;font-size: 16px;line-height: 20px;">Ubah Nomor HP</div>
						<div style="font-style: normal;font-weight: 400;font-size: 14px;line-height: 20px;">Masukan nomor handphonemu yang tersambung dengan Whatsapp</div>
						
						<!-- <form role="form" id="form_user" @submit="submitForm"> -->
							<div class="card-body">
								<div class="row">
									<div class="col-sm-12">
										<div class="form-group">
											<label for="exampleInputEmail1">No Telepon</label>
											<input type="number" value="" class="form-control" v-model="form.tel" placeholder="Nomor Telepon"/>
										</div>
									</div>
								</div>
							</div>
							<!-- /.box-body -->
							<div class="card-footer form-actions" @click="loadOTP">
								<button type="submit" id="tb_save"  class="btn btn-primary">
									<i class="fa fa-pen"></i> Simpan
								</button>
							</div>
						<!-- </form> -->

						<div class="overlay d-flex justify-content-center align-items-center" v-if="loadingPin"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div><br>
						<!-- <div class="h6 font-weight mt-3">Masukkan 6 digit PIN otorisasi kamu untuk melanjutkan proses {{messagePin}}</div> -->
					</div>
					<div class="modal-footer" hidden></div>
				</div>
			</div>
		</div>
		<Pin :showPin="showPin" :messagePin="messagePin" v-on:change="submitCode" />
		<OneTimePassword v-on:change="handleCustomChange" v-bind:params="{
          	phoneNumber: form.tel,
          	otpType: form.otpType,
          	countShowOTP: countShowOTP,
			shipperPin:shipperPin
        }" />
	</section>
</template>
<script>
import $ from "jquery";
import { authFetch, auth } from "@/libs/hxcore";
import moment from "moment";
import OneTimePassword from "@/dialog/OneTimePassword";
import Swal from "sweetalert2";
import Pin from "@/dialog/Pin";

export default {
	props: {
		handleCode: String,
		code: String,
		params:Object
	},
	data() {
		return {
			issetPin: false,
			pinSet: '',
			pinStar: [false, false, false, false, false, false],
			pinCheck: true,
			pinMessage: '',
			loadingPin: false,
			phoneNumber: '',
			type:'',
			currentRequest: 0,
			countDown: '0',
			myInterval:undefined,
			form: {
        		tel: "",
				otpType: 'change_number'
			},
			shipperId : '',
			countShowOTP:0,
			showPin: true,
			messagePin: 'ubah rekening',
			shipperPin:'',
			roleid:0,
		};
	},
	components: {
		OneTimePassword,
		Pin,
	},
	created() {
		// this.countDownTimer();
	},
	beforeDestroy() {
		// window.removeEventListener('keyup', this.pinKeyPress);
	},
	created: function () {
    var uinfo = auth.user();
    if (uinfo) {
      this.roleid = uinfo.role_id;
    }
  	},
	methods: {
		submitNewNumber() {
			const e = this.$refs;
			var data = Object.keys(this.form)
				.map(
				(key) =>
					encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
				)
				.join("&");
			this.method = "PUT";
			var urlSubmit = "/users/profile/update_number/" + this.shipperId;
			authFetch(urlSubmit, {
				method: this.method,
				body: data,
			})
				.then((res) => {
				if (res.status === 201) {
				} else if (res.status === 400) {
				}
				return res.json();
				})
				.then((js) => {
				// this.errors = [];
				if (js.success) {
					localStorage.removeItem(`expiredToken-${this.form.otpType}`);
					$(this.$refs.formNewNumber).modal("hide");
					Swal.fire({
					title: 'Update Berhasil',
					icon: 'success',
					text: 'Data profil sudah diudate',
					showCloseButton: true
					});
					this.countShowOTP = 0;
				}
				else{
					if (this.roleid == 12){
						Swal.fire({
						title: 'Perubahan ditolak',
						icon: 'error',
						text: js.details.error,
						showCloseButton: true
						})
						if(js.close){
							$(this.$refs.formNewNumber).modal("hide");
							this.closeOPT();
							this.showPin = false;
						}
					} else {
						Swal.fire({
						title: 'Update Gagal',
						icon: 'error',
						text: js.message,
						showCloseButton: true
						})
						if(js.close_modal){
							localStorage.removeItem(`expiredToken-${this.form.otpType}`);
							$(this.$refs.formNewNumber).modal("hide");
							this.closeOPT();
							this.showPin = false;
						}
					}
				}
			});
			

			// console.log('sini');
			// ev.preventDefault();
		},
		backPin() {
			this.phoneNumber = '';
			// window.removeEventListener('keyup', this.pinKeyPress);
			$(this.$refs.formNewNumber).modal("hide");
			if (!this.issetPin) {
				this.$router.back();
			}
		},
		pinKeyPress(e) {
			var char = parseInt(e);
			if (Number.isInteger(char) && this.pinSet.length < 4) {
				this.pinStar[this.pinSet.length - 1] = false;
				this.pinStar[this.pinSet.length - 0] = true;
				this.pinSet = this.pinSet + char;
				if (this.pinSet.length > 3) {
					this.submitCode();
				}
			} else if (e == 'Backspace') {
				this.pinSet = this.pinSet.substr(0, this.pinSet.length - 1);
				this.pinStar[this.pinSet.length - 0] = false;
				this.pinStar[this.pinSet.length - 1] = true;
			} else if (e == 'Clear') {
				this.pinSet = '';
			}
		},
		showSetCode() {
			$(this.$refs.formNewNumber).modal("hide");
			this.$router.push({ path: "/settings/OneTimePassword"});
		},
		checkPin: function () {
			this.loadingPin = true;
			let urlSubmit = "/shipper/valid_pin/" + this.pinSet;

			authFetch(urlSubmit, 
			{
				method: "GET",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				res.json().then((json) =>
				{
					this.pinStar[this.pinSet.length - 1] = false;
					if (json.success) {
						// this.issetPin = false;
						// this.pinSet = '';
						// this.pinCheck = true;
						// this.pinMessage = '';
						// this.$emit("change");
						// // window.removeEventListener('keyup', this.pinKeyPress);
						// $(this.$refs.formNewNumber).modal("hide");
					} else {
						// this.pinSet = '';
						// this.pinCheck = false;
						// this.pinMessage = json.msg;
					}
					this.loadingPin = false;
				});
			});
		},
		// submitCode: function () {
		// 	this.$emit("change",this.pinSet);
		// },
		handleChange (event) {
              this.$emit("customChange", event.target.value.toUpperCase())
        },

		handleCustomChange (s) {
			this.form.code = s;
			this.submitNewNumber();
			// this.submitForm();
		},
		loadOTP: function (ev) {
			if(this.form.tel.charAt(0) !== "0" && (this.form.tel.charAt(0) !== "6" || this.form.tel.charAt(1) != '2')){
				Swal.fire({
					title: 'Nomer tidak valid',
					icon: 'error',
					showCloseButton: true
				})
			}else{
				this.showPin = !this.showPin;
				// this.countShowOTP += 1;
				ev.preventDefault();
			}
		},
		closeOPT: function (ev) {
			this.countShowOTP = 0;
			ev.preventDefault();
		},
		submitCode:function(pinSet){
			this.shipperPin = pinSet;
			this.countShowOTP += 1;
			this.showPin = false
		}
	},
	mounted() {	},
	watch: {
		params: function (old, val) {
			// console.log('old',old['phoneNumber']);
			this.type = old['otpType'];
			this.phoneNumber = old['phoneNumber'];
			this.currentRequest =  old['countShowOTP'];
			this.shipperId =  old['shipper'];
			if(old['countShowOTP']>0 && old['countShowOTP']!=val['countShowOTP']){
				this.issetPin = true;
				$(this.$refs.formNewNumber).modal("show");
			}else{
				$(this.$refs.formNewNumber).modal("hide");
			}
		},
	}
};
</script>
<style scoped>
	div.vue-pincode-input-wrapper {
		display: flex;
		justify-content: center;
	}

	input.vue-pincode-input {
		border-bottom: 1px solid orange;
		box-shadow: none !important;
	}

	.modal-backdrop {
	z-index: -1;
	}
	
	#otpModal {
  		background: rgba(0, 0, 0, 0.5);
	}
</style>